/* eslint-disable @typescript-eslint/naming-convention */
export const environment = {
    production: true,
    backendUrl : 'https://presales-etgs.com/portal/server/public',
    // backendUrl : 'https://presales-etgs.com/portal-test/server/public',

    // -----------------------------------
    // @    AUTHENTICATION
    // -----------------------------------
    client_id: '2',
    client_secret: 'U8vszj8byAWSELAo5f3a8WKAxjwHy454TOm0hdx4',
};
