/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/dashboard',
    },
    {
        id: 'opportunities',
        title: 'Opportunities',
        type: 'basic',
        icon: 'heroicons_outline:briefcase',
        link: '/opportunities',
    },
    {
        id: 'products',
        title: 'Products',
        type: 'basic',
        icon: 'heroicons_outline:chip',
        link: '/products',
    },
    {
        id: 'clients',
        title: 'Clients',
        type: 'basic',
        icon: 'heroicons_outline:office-building',
        link: '/clients',
    },
    {
        id: 'branches',
        title: 'Branches',
        type: 'basic',
        icon: 'heroicons_outline:globe-alt',
        link: '/branches',
    },
    {
        id: 'users',
        title: 'Users',
        type: 'basic',
        icon: 'heroicons_outline:user-group',
        link: '/users',
    },
    {
        id: 'demos',
        title: 'Demos',
        type: 'basic',
        icon: 'heroicons_outline:clipboard-list',
        link: '/demos',
    },
    {
        id: 'travel',
        title: 'Travels',
        type: 'basic',
        icon: 'heroicons_outline:globe',
        link: '/travel',
    },
    {
        id: 'reports',
        title: 'Reports',
        type: 'basic',
        icon: 'heroicons_outline:user-group',
        link: '/reports',
    },
    // {
    //     id: 'opp',
    //     title: 'Opportunities',
    //     type: 'basic',
    //     icon: 'heroicons_outline:briefcase',
    //     link: '/opportunity-list',
    // },
];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/dashboard',
    },
    {
        id: 'opportunities',
        title: 'Opportunities',
        type: 'basic',
        icon: 'heroicons_outline:briefcase',
        link: '/opportunities',
    },
    {
        id: 'demos',
        title: 'Demos',
        type: 'basic',
        icon: 'heroicons_outline:clipboard-list',
        link: '/demos',
    },
    {
        id: 'travel',
        title: 'Travels',
        type: 'basic',
        icon: 'heroicons_outline:globe',
        link: '/travel',
    },
    {
        id: 'products',
        title: 'Products',
        type: 'basic',
        icon: 'heroicons_outline:chip',
        link: '/products',
    },
    {
        id: 'clients',
        title: 'Clients',
        type: 'basic',
        icon: 'heroicons_outline:office-building',
        link: '/clients',
    },
    {
        id: 'branches',
        title: 'Branches',
        type: 'basic',
        icon: 'heroicons_outline:globe-alt',
        link: '/branches',
    },
    {
        id: 'users',
        title: 'Users',
        type: 'basic',
        icon: 'heroicons_outline:user-group',
        link: '/users',
    },
    {
        id: 'reports',
        title: 'Reports',
        type: 'basic',
        icon: 'heroicons_outline:user-group',
        link: '/reports',
    },
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/dashboard',
    },
    {
        id: 'opportunities',
        title: 'Opportunities',
        type: 'basic',
        icon: 'heroicons_outline:briefcase',
        link: '/opportunities',
    },
    {
        id: 'demos',
        title: 'Demos',
        type: 'basic',
        icon: 'heroicons_outline:clipboard-list',
        link: '/demos',
    },
    {
        id: 'travel',
        title: 'Travels',
        type: 'basic',
        icon: 'heroicons_outline:globe',
        link: '/travel',
    },
    {
        id: 'products',
        title: 'Products',
        type: 'basic',
        icon: 'heroicons_outline:chip',
        link: '/products',
    },
    {
        id: 'clients',
        title: 'Clients',
        type: 'basic',
        icon: 'heroicons_outline:office-building',
        link: '/clients',
    },
    {
        id: 'branches',
        title: 'Branches',
        type: 'basic',
        icon: 'heroicons_outline:globe-alt',
        link: '/branches',
    },
    {
        id: 'users',
        title: 'Users',
        type: 'basic',
        icon: 'heroicons_outline:user-group',
        link: '/users',
    },
    {
        id: 'reports',
        title: 'Reports',
        type: 'basic',
        icon: 'heroicons_outline:user-group',
        link: '/reports',
    },
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/dashboard',
    },
    {
        id: 'opportunities',
        title: 'Opportunities',
        type: 'basic',
        icon: 'heroicons_outline:briefcase',
        link: '/opportunities',
    },
    {
        id: 'demos',
        title: 'Demos',
        type: 'basic',
        icon: 'heroicons_outline:clipboard-list',
        link: '/demos',
    },
    {
        id: 'travel',
        title: 'Travels',
        type: 'basic',
        icon: 'heroicons_outline:globe',
        link: '/travel',
    },
    {
        id: 'products',
        title: 'Products',
        type: 'basic',
        icon: 'heroicons_outline:chip',
        link: '/products',
    },
    {
        id: 'clients',
        title: 'Clients',
        type: 'basic',
        icon: 'heroicons_outline:office-building',
        link: '/clients',
    },
    {
        id: 'branches',
        title: 'Branches',
        type: 'basic',
        icon: 'heroicons_outline:globe-alt',
        link: '/branches',
    },
    {
        id: 'users',
        title: 'Users',
        type: 'basic',
        icon: 'heroicons_outline:user-group',
        link: '/users',
    },
    {
        id: 'reports',
        title: 'Reports',
        type: 'basic',
        icon: 'heroicons_outline:user-group',
        link: '/reports',
    },
];
